@import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css';
@font-face {
    font-family: ComfortaaRegular;
    src: url('./Assets/Fonts/comfortaa-regular.eot'); 
    src: local('Montserrat'), local('Montserrat'),
         url('./Assets/Fonts/comfortaa-regular.otf') format('embedded-opentype'), 
         url('./Assets/Fonts/comfortaa-regular.woff') format('woff'), 
         url('./Assets/Fonts/comfortaa-regular.TTF') format('truetype'), 
         url('./Assets/Fonts/comfortaa-regular.svg') format('svg'); 
}
@font-face {
    font-family: DubaiRegular;
    src: url('./Assets/Fonts/dubai.eot'); 
    src: local('Montserrat'), local('Montserrat'),
         url('./Assets/Fonts/dubai.otf') format('embedded-opentype'), 
         url('./Assets/Fonts/dubai.woff') format('woff'), 
         url('./Assets/Fonts/dubai.ttf') format('truetype'), 
         url('./Assets/Fonts/dubai.svg') format('svg'); 
}
.modalTransition{
  transition: ease-in 0.2s !important;
  transform: translateX(0%) !important;
}
body{
    margin:0;
    padding:0;
    font-size:16px;
    font-family: DubaiRegular !important;
}
.section-head h2 span, .promotional-services .promotional-right p span, .footer-widget .about p span, 
.about-s02 .about-text p span, .service-text p span, .solution-sections .solutions-text ul li span{
    font-family: ComfortaaRegular !important;
}
a, a:hover{
    text-decoration:none !important;
}
.wrapper{
    padding-left:0 !important;
    padding-right:0 !important;;
}
.btn-animate{
	position:relative;
	overflow:hidden;
}
.btn-animate:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: -100%;
    background-color: #36D3BA;
    -webkit-transition: right 0ms ease-in-out;
    transition: right 0ms ease-in-out;
}
.btn-animate:hover:before {
    right: 100%;
    -webkit-transition: right 400ms ease-in-out;
    transition: right 400ms ease-in-out;
}
.btn-animate:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: -100%;
    background-color: #2E8F8D;
    -webkit-transition: right 0ms ease-in-out;
    transition: right 0ms ease-in-out;
}
.btn-animate:hover:after {
    right: 100%;
    -webkit-transition: right 600ms ease-in-out;
    transition: right 600ms ease-in-out;
}
.main-menu ul li.dropdown a:before {
    position: absolute;
    content: "";
    background: #fff;
    width: 100%;
    height: 2px;
    bottom: -17px;
    padding-bottom: 20px;
    opacity: 0;
    z-index: 0;
}
.main-menu ul li.dropdown .dropdown-menu{
    left: 20px;
    right: 20px;
    display: block;
    top: 40px;
    overflow: hidden;
    height: 0;
    border: 0 none;
    border-radius:25px;
    padding: 0;
    transition: height ease-in-out 500ms;
}
.main-menu ul li.dropdown{
    position: inherit;
    padding-bottom: 0;
}
.main-menu ul li.dropdown:hover .dropdown-menu{
    height: max-content;
}
.main-menu ul li.dropdown .dropdown-menu.services{
    left:-5%;
    right:0;
    width:110%;
}
.main-menu ul li.dropdown:hover .dropdown-menu.services{
    height:max-content;
}
.main-menu ul li.dropdown .dropdown-menu > .row{
    padding:30px 0px 30px 50px;
}
.main-menu ul li.dropdown .dropdown-menu.services > .row{
    padding-left:30px;
    padding-right:20px;
}
.dropdown-menu .dropdown-single{
    float:left;
}
.dropdown-menu .dropdown-single:last-child{
    padding-left:0;
}
.dropdown-menu .dropdown-single ul{
    float:left;
    padding-left:0;
    width:100%;
}
.dropdown-menu .dropdown-single ul li{
    float:left;
    width:100%;
    margin-right:0;
    margin-bottom:15px;
}
.dropdown-menu .dropdown-single ul li a{
    color:#000;
    font-size:16px;
    font-weight:600;
    line-height:50px;
    text-transform:uppercase;
    float:left;
    width:100%;
}
.dropdown-menu.services .dropdown-single ul li{
    margin-bottom:10px;
}
.dropdown-menu.services .dropdown-single ul li a{
    text-transform:capitalize;
    font-weight:400;
    font-size:14px;
    line-height:26px;
}
.dropdown-menu .dropdown-single ul li a img{
    float:left;
    margin-right:15px;
}
.dropdown-menu .dropdown-single ul li a span{
    float: left;
    text-align: left;
    line-height: 50px;
}
.dropdown-menu .dropdown-single ul li a:hover{
    color:#36D3BA;
}
.section-head{
    text-align:center;
    margin-bottom:35px;
}
.section-head h2{
    font-size:60px;
    line-height:90px;
    color:#000;
    font-weight:600;
}
.section-head h3{}
.section-head p{
    font-size:17px;
    color:#000000;
    line-height:30px;
    font-weight:400;
}
.section-head a{}
.header{
    background-color:#000;
    position:relative;
}
.header nav{
    position: absolute;
    top:0;
    left:0;
}
.header nav{
    float:left;
    width:100%;
    padding-left:0;
    padding-right:0;
    padding-top:30px;
}
.main-menu{}
.main-menu ul{
    width:max-content;
    margin:auto;
}
.main-menu ul li{
    float:left;
    margin-right:35px;
    list-style:none;
}
.main-menu ul li a{
    font-size:18px;
    color:#fff;
    line-height:26px;
    position:relative;
}
.header nav .get-in-touch-button {
    padding-left:0;
    padding-right:0;
}
.header nav .get-in-touch-button a{
    background-image:linear-gradient(to right, #2E8F8D, #233754);
    padding:10px 25px;
    border-radius:50px;
    font-size:18px;
    color:#fff;
    line-height:26px;
    display:inline-block;
    width:100%;
    text-align:center;
}
.slider{
    background-image:url(./Assets/Img/home-bg2.svg);
    background-size:cover;
    min-height:650px;
    
    padding-top:180px;
}
.inner-slider{
    background-image:url(./Assets/Img/inner-slider-bg.png);
    background-size:cover;
    min-height:400px;
    padding-bottom:130px;
    padding-top:200px;
}
.subheader{
    text-align:center;
}
.subheader h2{
    font-size:50px;
    color:#ffffff;
    font-weight:700;
    text-transform:uppercase;
}
.subheader ul{
    width:max-content;
    margin:auto;
    background-color:transparent;
}
.subheader ul li{
    font-size:16px;
    line-height:26px;
    text-transform:uppercase;
    margin-right:50px;
    position:relative;
}
.subheader ul li:last-child{
    margin-right:0;
}
.subheader ul li a{
    color:#fff;
}
.subheader ul li span{
    color:#ffffff;
}
.subheader ul li:before{
    position:absolute;
    content:'';
    top:0;
    bottom:0;
    margin:auto;
    right:-25px;
    background-color:#ffffff;
    width: 6px;
    height:6px;
    border-radius:50%;
}
.subheader ul li:last-child:before{
    display:none;
}
.slider > .row{
    margin-left:0;
    margin-right:0;
    position: relative;
    z-index: 4;
}
.slider .home-slider .slick-slide{
    text-align:center;
}
.slider .home-slider .slick-list{
    padding-bottom:35px;
}
.slider .home-slider h3{
    font-size:50px;
    font-weight:700;
    color:#fff;
    line-height:60px;
    text-transform:uppercase;

    margin-bottom:35px;
}
.slider .home-slider a{
    display:inline-block;
    background-color:#fff;
    border-radius:50px;
    margin:auto;
    padding:10px 35px;
    font-size:16px;
    color:#000000;
    font-weight:600;
    text-transform:uppercase;
}
.slider .home-slider .slick-dots li button:before{
    color:#fff;
    font-size:16px;
    opacity:0.75;
    width:24px;
    height:24px;
    border:3px solid transparent;
    border-radius:50%;
}
.slider .home-slider .slick-dots li.slick-active button:before{
    border:3px solid #fff;
}
.home-s02{
    box-shadow:1px 1px 10px 3px #eee;
    margin-top:150px;
    
}
.home-s02 .single-s02{
    text-align:center;
    margin-bottom:35px;
    position:relative;
    top:0;
    left:0;
    padding-top:40px;
    padding-bottom:200px;
   
}
/* .home-s02 .single-s02 > div {
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    top: -60px;
    bottom: 0;
    
} */
.home-s02 .single-s02 div.icon {
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    top: -60px;
    bottom: 0;
    
}
.home-s02 .single-s02:nth-child(4) > div, .home-s02 .single-s02:nth-child(5) > div, .home-s02 .single-s02:last-child > div{
    
    padding-bottom: 15px;
}
.home-s02 .single-s02:nth-child(4), .home-s02 .single-s02:nth-child(5), .home-s02 .single-s02:last-child{
    margin-bottom:0;
    padding-bottom:180px;
}
.home-s02 .single-s02 img{
    float:left;
    width:100%;
    position:absolute;
    left:0;
    top:0;
    right:0;
    margin:auto;
    bottom:0;
    
    transition: transform 400ms linear;
}
.home-s02 .single-s02:hover img{
    
    animation: moveThis 4s linear infinite 400ms;
    transform: scale(1.2);
    
}
@keyframes moveThis {
    0% {
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;
    }
    25% {
        left: 7%;
        right: -3%;
        top: 0%;
        bottom: 0%;
    }
    50% {
        left: 0%;
        right: 0%;
        top: 4%;
        bottom: -6%;
    }
    75% {
        left: -6%;
        right: 4%;
        top: -4%;
        bottom: 5%;
    }
    100% {
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;
    }
}
.icon {
    width: 270px;
    height: 270px;
    position: absolute;
   
    left: 50%;
    top: -130px;
    /* transform: translateX(-50%); */
}
.icon img {
    position: absolute;
    transition: transform 400ms linear;
}
.icon:hover img {
    animation: moveThis 4s linear infinite 400ms;
    transform: scale(1.2);
}
.home-s02 .single-s02 h2{
    font-size:28px;
    line-height:42px;
    color:#000;
    font-weight:600;
    margin-bottom:0;
}
.home-s02 .single-s02 p{
    font-size:16px;
    color:#000;
    line-height:30px;
}
.partners{
    padding-top:80px;
    padding-bottom:80px;
}
.partners .partner-slider{}
.partners .partner-slider .slick-slide{
    padding:25px;
}
.partners .partner-slider .slick-slide > div {
    background-color:#ffffff;
    box-shadow:1px 1px 10px 3px #eee;
    display:flex !important;
    padding:10px 0;
    align-items:center;
    min-height:80px;
}
.partners .partner-slider .slick-slide > div img{
    margin:auto;
    max-width:70%;
}
.partner-slider .slick-arrow, .partner-slider .slick-arrow:hover{
    
    
    border-radius: 50%;
    width: 21px;
    height: 21px;
}
.partner-slider .slick-arrow:before{
    color:#2E8F8D;
    opacity:1;
    background-color:#ffffff;
}
.why-ana{
    background-image:url(./Assets/Img/why-ana-bg.png);
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom:80px;
}
.lets-work{
    padding-bottom:40px;
    /* background-image:url(./Assets/Img/lets-work-bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom; */
    background: url(./Assets/Img/gradient.png);
    background-position: fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
}
.lets-work{
    position:relative;
    z-index: 10;
    overflow: hidden;
}
.lets-work > div.row{
    position:relative;
    z-index:4;
}
.why-ana .why-ana-img{}
.why-ana .why-ana-img img, .lets-work .lets-work-img img{
    float:left;
    width:100%;
}
.why-ana .why-ana-text{}
.why-ana .why-ana-text p{
    /* text-align:right; */
    margin-bottom:30px;
    text-align:left;
}
.why-ana .why-ana-text a, .view-services a{
    background-image:linear-gradient(to right, #2E8F8D, #233754);
    float:right;
    padding: 10px 35px;
    border-radius: 50px;
    font-size: 18px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    text-transform:uppercase;
}
.lets-work .lets-work-text{
    text-align: left;
}
.lets-work .lets-work-text h2{
    line-height:70px;
    margin-bottom:20px;
}
.lets-work .lets-work-text p{
    color:#fff
}
.lets-work .lets-work-text p a{
    font-weight:600;
    font-size:30px;
    line-height:60px;
    color:#fff;
}
.lets-work .lets-work-text p a.mail{
    font-size:20px;
    font-weight:400;
    line-height: 30px;
}
.lets-work .lets-work-text >  a{
    background-color:#36D3BA;
    display:inline-block;
    padding: 10px 35px;
    border-radius: 50px;
    font-size: 18px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    text-transform:uppercase;
}
.counter{
    padding:60px 0 80px 0;
}
.counter .singleCounter > div{
    padding:25px;
    box-shadow:1px 1px 10px 1px #ccc;
    text-align:center;
    min-height: 185px;;
}
.counter .singleCounter h4{
    font-size:60px;
    color:#2E8F8D;
    font-weight:600;
    line-height:60px;
}
.counter .singleCounter p{
    font-size:20px;
    line-height:26px;
    color:#000;
    text-transform:uppercase;
}

.promotional-services{}
.promotional-services .promotional-left{}
.promotional-services .promotional-right{
    text-align:right;
}
.promotional-left > .animated{
    width:85%;
    float:right;
    margin-bottom:20px;
    border:1px solid #000;
    border-radius:30px;
}
.promotional-left .single-promo{
    
    padding:25px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:100%;
    
}
.promotional-left > .animated:nth-child(even){
    float:left;
    box-shadow:1px 1px 10px 5px #eee;
    border:1px solid transparent;
}
/* .promotional-left .single-promo:nth-child(even){
    float:left;
    box-shadow:1px 1px 10px 5px #eee;
    border:1px solid transparent;
} */
.promotional-left .single-promo > img{
    margin-right:20px;
    width:100%;
    max-width:100px;
    height:max-content;
}
.single-promo .single-promo-text{}
.single-promo .single-promo-text h2{
    font-size:22px;
    color:#000;
    font-weight:600;
    margin-bottom:15px;
}
.single-promo .single-promo-text p{
    font-size:16px;
    color:#000;
    line-height:22px;
}
.promotional-services .promotional-right h2{
    line-height:60px;
    margin-bottom:35px;
}
.promotional-services .promotional-right p{
    font-size:18px;
    line-height:26px;
    margin-bottom:35px;
}
.promotional-services .promotional-right img{
    float:right;
    width:max-content;
}
.testimonials{
    padding:80px 0;
}
.testimonial-slider{}
.testi-inner{
    position:relative;
}
.testi-inner:before {
    background-image: url(./Assets/Img/testimonial-inner-bg.png);
    background-position: top center;
    position: absolute;
    content: "";
    width: 100%;
    height: 200px;
    top: -150px;
    left:0;
}
.testimonial-slider .slick-slide > div{
    width:100%;
    float:left;
    padding:10px 0;
}
.testimonial-slider .slick-slide > div img{
    float:left;
    margin-right:20px;
    max-width:350px;
}
.testimonial-slider .slick-slide > div .testi-content{
    background-color:#fff;
    box-shadow:1px 1px 10px 3px #ccc;
    padding:40px 55px;
    float:left;
    max-width:60%;
    position:relative;
}
/* .testimonial-slider .slick-slide > div .testi-content:before{
    position: absolute;
    content: '';
    left: 0;
    bottom: -46px;
    
    border: 23px solid transparent;
    border-top: 23px solid;
    border-top-color: #ffffff;
   } */
.testimonial-slider .slick-slide > div .testi-content p,
.testimonial-slider .slick-slide > div .inner-testimonials p{
    font-size:16px;
    color:#000;
    line-height:30px;
}
.testimonial-slider .slick-slide > div .testi-content strong,
.testimonial-slider .slick-slide > div .inner-testimonials strong{
    font-size:24px;
    color:#000;
    font-weight:600;
    display:block;
    margin-bottom:10px;
}
.testimonial-slider .slick-slide > div .testi-content span, 
.testimonial-slider .slick-slide > div .inner-testimonials span{
    font-size:16px;
    font-style:italic;
}
.testimonial-slider .slick-slide > div .inner-testimonials{
    padding:0 55px 0px 55px;
    text-align:center;
    display:block;
    margin:auto;
    max-width:90%;
    position:relative;
    margin-bottom:35px;
}
.testimonial-slider .slick-dots{}
.testimonial-slider .slick-dots li{

}
.testimonial-section{
    margin-bottom:80px;
}
.testimonial-slider .slick-dots li button:before, .customer-slider .slick-dots li button:before{
    color:#C4C4C4;
    font-size:20px;
    opacity: 1;
}
.testimonial-slider .slick-dots li.slick-active{}
.testimonial-slider .slick-dots li.slick-active button:before,
.customer-slider .slick-dots li.slick-active button:before{
    color:#2E8F8D;
}
.testimonial-slider-img{
    width:300px;
    margin:auto;
}
.testimonial-slider-img .slick-list{
    padding:30px 0 60px 0 !important;
}
.testimonial-slider-img .slick-list .slick-slide img{
    width:100%;
    float:left;
    
}
.testimonial-slider-img .slick-list .slick-slide div:focus{
    outline:0;
    border:0 none;
}
.testimonial-slider-img .slick-list .slick-slide.slick-active img{
    
}

.testimonial-slider-img .slick-list .slick-slide.slick-center img{
    transform:scale(1.5);
}

.customers{
    padding-bottom:150px;
}
.customer-slider{
    
}
.customer-slider .slick-slide{
    padding:25px 
}
.customer-slider .slick-slide > div > div {
    width:100%;
    float:left;
    padding:15px 25px;
    box-shadow: 1px 1px 10px 1px #ccc;
    margin-bottom:25px;
    min-height:130px;
    display:flex !important;
    align-items:center;
}
.customer-slider .slick-slide > div img{
    float:left;
    width:max-content;
    max-width:100%;
}
.projects{
    background-image:url(./Assets/Img/projects-bg.png);
    background-size:contain;
    background-position:bottom center;
    background-repeat:no-repeat;
    padding-top:60px;
    padding-bottom:60px;
}
.project-tabs{
    float:left;
    width:100%;
}
.project-tabs ul{
    padding-left:0;
    width:max-content;
    margin:auto;
}
.project-tabs ul li{
    float:left;
    color:#2E8F8D;
    font-size:18px;
    font-weight:700;
    padding:5px 35px;
    border-radius:50px;
    text-transform:uppercase;
}
.project-tabs ul li.react-tabs__tab--selected, .project-tabs ul li:hover{
    background-image:linear-gradient(to left, #2E8F8D, #233754);
    color:#ffffff;
}
.project-tabs .project-tabcontent{
    float:left;
    width:100%;
    
}
.project-tabs .project-tabcontent.react-tabs__tab-panel--selected{
    margin-top:60px;
}
.single-project-tab{
    position:relative;
    width:49%;
    float:left;
    margin-bottom:35px;
}
.single-project-tab:nth-child(even){
    margin-left:2%;
}
.single-project-tab:nth-child(2){
    margin-top:35px;
}
.single-project-tab:nth-child(3){
    margin-top:-35px;
}
.single-project-tab > img{
    position:relative;
    z-index: 1;
    float:left;
    width:100%;
}
.single-project-tab .single-project-content{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    top: 0;
    margin: auto;
    padding:30px;
    background-color:rgba(46, 143,141,0.8);
    border-radius:30px;
    transition: opacity 500ms linear;
}
.single-project-tab:hover .single-project-content {
    opacity: 1;
}
.single-project-content h2{
    color:#fff;
    font-size:26px;
    font-weight:600;
    line-height:30px;
}
.single-project-content p{
    font-size:18px;
    line-height:30px;
    color:#fff;
    text-align:center;
}
.single-project-content a{
    background-color:#fff;
    border-radius:50px;
    text-transform:uppercase;
    padding:5px 35px;
    font-size:16px;
    color:#000;
    line-height:25px;
    font-weight:600;
}
.projects .full{
    text-align:center;
    float:left;
    width:100%;
}
.projects .full a{
    display: inline-block;
    background-color: #fff;
    border-radius: 50px;
    margin: auto;
    padding: 10px 35px;
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration:none;
}

/* About Us */

.about-s02{
    padding:80px 0;
}
.about-s03{
    background-image:url(./Assets/Img/about-s03-bg.png);
    background-size:cover;
    background-repeat:no-repeat;
    padding-bottom:40px;
    
}
.about-s02 .about-text{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
}
.section-head.about-text, .section-head.service-text {
    text-align:left;
}
.about-s02 .about-text p{
    font-size:18px;
    color:#000;
    line-height:26px;
    margin-bottom:25px;
}
.about-s02 .about-text ul, .solution-sections .solutions-text ul {
    margin-top:30px;
    margin-bottom:25px;
    padding-left:20px;
    text-align:left;
}
.about-s02 .about-text ul li{}
.about-s02 .about-text a, .service-text a, .solutions-text a, .single-jobs a{
    background-image:linear-gradient(to right, #2E8F8D, #233754);
    display:inline-block;
    padding: 10px 35px;
    border-radius: 50px;
    font-size: 18px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    text-transform:uppercase;
}
.about-s02 .about-img{

}
.about-s02 .about-img img{
    float:left;
    width:100%;
}

/* Solution Sections */
.solution-sections{
    padding:80px 15px;
}
.solution-sections .solutions-row{
    margin-bottom:65px;
    align-items:center;
}
.solution-sections .solutions-img{
    float:left;
    width:100%;
}
.solution-sections .solutions-img img{
    float:left;
    width:100%;
}
.solution-sections .solutions-text{}
.solution-sections .solutions-text h2{
    font-size:50px;
}
.solution-sections .solutions-text p{}
.solution-sections .solutions-text ul{
    margin-top:0;
}
.solution-sections .solutions-text ul li {
    position:relative;
    padding-left:25px;
    list-style:none;
    margin-bottom:15px;
    line-height:26px;
}
.solution-sections .solutions-text ul li:before{
    position: absolute;
    content: "\f00c";
    left: 0;
    color: #36d3ba;
    font-family: fontawesome;
}
.solution-sections .solutions-text a, .solution-sections .solutions-text a:hover{
    color:#ffffff;
}


.service-sections{
    padding:80px 0;
}
.service-sections > .row{
    position:relative;
}
.service-sections .service-img{
    position: sticky;
    position:-webkit-sticky;
    height:max-content;
    top:84px;
}
.service-text{
    counter-reset: p-counter;
}
.section-head.service-text h2, .section-head.solutions-text h2{
    margin-bottom:0px;
    line-height:80px;
    font-size:45px;
}
.section-head.service-text.security-service h2, .section-head.solutions-text.security-service h2{
    line-height:60px;
}
.service-img img{
    float:left;
    width:100%;
    margin-bottom:60px;
}
.service-text p{
    margin-bottom:10px;
    text-align:justify;
    line-height:26px;
    position:relative;
    padding-left:20px;
}
/* .service-text p:before{
position:absolute;
counter-increment: p-counter;
  content: ""counter(p-counter)". ";
  left:0;
} */
.service-text p:before{
    position:absolute;
    /* counter-increment: p-counter; */
    content: "";
    left: 0;
    width: 8px;
    height: 8px;
    background: #000;
    border-radius: 50%;
    top: 8px;
    }
.service-text.sip-trunk .single-sip{
    counter-reset: p-counter;
}
.service-text.sip-trunk .single-sip.why-choose p:before{
    display:none;
}
.service-text.sip-trunk .single-sip p:before{
    position:absolute;
    /* counter-increment: p-counter;
    content: ""counter(p-counter)". "; */
    left:0;
}
.service-text a, .service-text a:hover{
    color:#ffffff;
    width:max-content;
    cursor:pointer;
}
.service-text h4{
    margin-top:35px;
    font-size:1.4rem;
}
.service-text ul{
    margin-bottom:25px;
    padding-left:20px;
}
.service-text ul li{
    color:#000;
    text-align:justify;
    margin-bottom:5px;
    position:relative;
    list-style:none;
    padding-left:25px;
}
.service-text ul li:before{
    position:absolute;
content:'\f00c';
left:0;
color:#36D3BA;
font-family:fontawesome;
}
.contact{
    padding:80px 0;
}
.industry-sections{ 
    padding:80px 0;
}
.industry-single{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding:35px;
}
.industry-single:hover{
    background-color:#19adb0;
    border-radius:10px;
}
.industry-single:hover .industry-text h2,
.industry-single:hover .industry-text p{
    color:#ffffff;
}
.industry-single .industry-img{
    margin-right:20px;
}
.industry-single .industry-text{}
.industry-single .industry-text h2{
    font-size:26px;
    color:#19716b;
    font-weight:600;
    text-transform:uppercase;
}
.industry-single .industry-text p{
    font-size:18px;
    color:#414141;
    line-height:26px;
}

.footer-section{
    background-image:url(./Assets/Img/footer-bg.png);
    
}
.footer-section .container > .row{
    margin-right:0;
    margin-left:0;
    align-items:center;
    
}
.footer-section .container .footer-widget{
    margin-bottom:60px;
}
.footer-section .container > .get-in-touch{
    align-items:center;
}
.footer-section .container > .get-in-touch.contact-page-footer{
    align-items:flex-end;
}
.get-in-touch{
    margin-bottom:80px;
}
.get-in-touch .section-head h2{
    text-transform:uppercase;
    text-align:left;
}
form#get-in-touch-form{
    float:left;
    width:100%;
    box-shadow:1px 1px 7px 1px #eee;
    padding:35px;
    background-color:#fff;
}
form#get-in-touch-form .half{
    float:left;
    width:49%;
    margin-bottom:35px;
}
form#get-in-touch-form .half:nth-child(even){
    margin-left:2%;
}
form#get-in-touch-form .full{
    float:left;
    width:100%;
    margin-bottom:35px;
    
}
form#get-in-touch-form .half input, form#get-in-touch-form .full input,
form#get-in-touch-form .full textarea{
    border:1px solid;
    border-color:rgba(0,0,0,0.50);
    border-radius:0;
    height:50px;
}
form#get-in-touch-form .full textarea{
    min-height:100px;
}
form#get-in-touch-form .full.submit-btn{
    text-align:center;
    margin-bottom:0;
}
form#get-in-touch-form .full.submit-btn button{
background-image:linear-gradient(to left, #2E8F8D, #233754);
border-radius:50px;
text-transform:uppercase;
padding:10px 35px;
font-size:16px;
color:#ffffff;
line-height:26px;
}
.get-intouch-img{
    background-image:url(./Assets/Img/get-in-touch-bg.png);
    background-repeat:no-repeat;
    background-size:cover;
    display:inline-flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.get-intouch-img img{
    float:left;
    max-width:70%;
    width:auto;
    margin-right:-50px;
    
}
.subscribe{
    padding:30px 0;
    border-top:1px solid #fff;
    border-bottom:1px solid #fff;
    margin-bottom:60px;
}
.subscribe .full{
    padding-left:0;
    padding-right:0;
}
.subscribe .half{
    display:flex;
    align-items:center;
}
.subscribe .half span{
    font-size:40px;
    margin-right:20px;
    color:#fff;
}
.subscribe .half h3{
    font-size:24px;
    color:#fff;
    line-height:24px;
    margin-bottom:0;
    
}
.subscribe .half a{
    font-size:16px;
    /* color:#8A8A8A; */
    color:#fff;
}
.subscribe .half p{
    font-size:18px;
    color:#fff;
    line-height:22px;
    margin-bottom:0;
}
.subscribe .half h6{
    font-size:24px;
    color:#fff;
    text-transform:capitalize;
    font-weight:bold;
    letter-spacing: 1px;
}
#subscription-form{
    display:flex;
    width:100%;
}
#subscription-form .subs-email{
    float:left;
    /* width:80%; */
    width: 67%;
}
#subscription-form .submit-btn{
    float:left;
    width: 33%;
}
#subscription-form .submit-btn button{
    /* width:100%; */
    text-transform:uppercase;
    color:#ffffff;
    background-color:#2E8F8D;
    border-radius:0 50px 50px 0;
    height:50px;
}
#subscription-form .subs-email input{
    background-color:#fff;
    border-radius:50px 0 0 50px;
    border:1px solid #fff;
    min-height:50px;
}
.footer-widget{}
.footer-widget h4{
    font-size:24px;
    line-height:30px;
    color:#fff;
    margin-bottom:30px;
    font-weight:700;
    text-transform:uppercase;
}
.footer-widget .about p{
    font-size:16px;
    line-height:24px;
    color:#fff;
    float:left;
    width:90%;
}
.footer-widget .quick-links ul{
    padding-left:0;
}
.footer-widget .quick-links ul li{
    line-height:30px;
    list-style:none;
}
.footer-widget .quick-links ul li a{
    font-size:16px;
    color:#fff;
}
.footer-widget .social{}
.footer-widget .social a{
    float:left;
    color:#fff;
    margin-right:20px;
}
.footer-widget .social a i{}
.footer-copyright{
    border-top:1px solid #fff;
    padding-top:20px;
}
.footer-copyright p{
    font-size:16px;
    color:#fff;
    line-height:27px;
    text-align:center;
}
.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .get-touch-popup{
      margin-bottom:0;
  }
  .get-touch-popup .section-head h2{
      font-size:30px;
      line-height:32px;
      font-weight:600;
  }
  .get-touch-popup .section-head p{
      font-size:14px;
      line-height:22px;
      text-align:left;
  }
  .get-touch-popup form#get-in-touch-form{
      box-shadow:0 0 0 transparent;
      padding:0;
  }
  .modal-lg .modal-body{
      padding-right:0;
      padding-top:0;
      padding-bottom:0;
  }
  .get-touch-popup .col-lg-6:first-child{
      padding-top:50px;
      padding-bottom:50px;
      padding-left:50px;
  }
  .get-touch-popup form#get-in-touch-form .half,
  .get-touch-popup form#get-in-touch-form .full{
      margin-bottom:20px;
  }
  .get-touch-popup form#get-in-touch-form .half input, 
  .get-touch-popup form#get-in-touch-form .full input,
  .get-touch-popup form#get-in-touch-form .full select,
  .get-touch-popup form#get-in-touch-form .full textarea{
      border:1px solid #ccc;
      
  }
  .get-touch-popup form#get-in-touch-form .full select{
      height:50px;
      width:100%;
  }
  .get-touch-popup form#get-in-touch-form .half select {
    height: 50px;
}
  .contact-button, .menu-icon{
      display:none;
  }

  .careers-section{
      padding:60px 0;
  }
  .single-jobs{
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      align-items:center;
      border-bottom:1px solid #ccc;
      padding:15px 0;
  }
  .single-jobs h2{
    font-size: 22px;
    color: #000;
    font-weight: 600;
    margin-bottom:0;
   line-height:50px;
  }
  .single-jobs h4{
    font-size: 18px;
    color: #000;
    font-weight: 500;
   line-height:50px;
    margin-bottom: 0px;
  }
  .single-jobs a{}
  .single-jobs a:hover{
      color:#fff;
  }
  .contact .contact-text img{
      width:100%;
  }
  .contactaddress{
      text-align: left;
  }
  .contactaddress h4{
      font-weight:600;
      text-transform: capitalize;
      letter-spacing: 1px;
  }
  .contactaddress p{
      line-height: 22px;
      letter-spacing: 1px;
  }
  .contactaddress p:not(:nth-child(2)) {
    margin-bottom: 0;
}
  .contactaddress p a{

  }

  .terms-col{
      text-align:left;
      padding-top:60px;
  }
  .terms-col h3{
      font-size:40px;
      margin-bottom:15px;
  }
  .terms-col .single-term{
      margin-bottom:35px;
  }
  .terms-col .single-term h4{
      margin-bottom:0;
      line-height:22px;
  }
  .terms-col .single-term p{
      font-size:16px;
  }
  .terms-col .single-term p strong{}
  .lets-work .lets-work-text{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}
.lets-work .lets-work-text h2{
    margin-bottom:30px;
}
.submenu-close{
    display:none;
}

.contactaddress > p:not(:nth-child(2)) {
    margin-bottom: 0;
}

@media screen and (min-width:2200px){

    .lets-work .lets-work-text p a {
        color: #000;
    }

}


  @media screen and (min-width:1660px){
    #ani-banner{
        min-height:700px;
    }
    .lets-work .lets-work-text p:nth-child(2) {
        color: #000;
    }
    .lets-work .lets-work-text
    {
    margin-bottom:60px !important;
    }

  }
  @media screen and (min-width:1800px){
    .lets-work .lets-work-text
    {
    margin-bottom:70px !important;
    }
    .lets-work .lets-work-text p:nth-child(3) a:first-child {
        color: #000;
    }
}
@media screen and (min-width:2000px){
    .lets-work .lets-work-text
    {
    margin-bottom:85px !important;
    }
    .lets-work .lets-work-text p:nth-child(3) a {
        color: #000;
    }
}
  @media screen and (min-width:1440px){
    #ani-banner{
        position:relative;
    }
    .lets-work .lets-work-text
    {
    margin-bottom:10px;
    }
    
  }
  @media screen and (min-width:1240px){
    .home-s02 .single-s02 img{
        max-width: 270px;
    }
    .contactaddress p:nth-child(2) {
        padding-right: 18px;
    }
  }
  @media screen and (min-width:1025px){
    .projects{
        min-height:1220px;
    }
    
  }

  @media screen and (min-width:768px) and (max-width:1024px){
    .header nav .get-in-touch-button a{
        padding:10px 20px;
    }
    .slider .home-slider h3{
        font-size:45px;
    }
    .home-s02 .single-s02 h2{
        font-size:25px;
    }
    .counter .singleCounter > div{
        padding:25px 20px;
    }
    .counter .singleCounter p{
        font-size:18px;
    }
    .single-promo .single-promo-text h2{
        font-size:18px;
    }
    .single-promo .single-promo-text p{
        font-size:15px;
    }
    .single-project-tab .single-project-content{
        border-radius:20px;
    }
    .home-s02{
        margin-top:100px;
    }
    .solution-sections .solutions-text h2{
        font-size:40px;
    }
    .testimonial-slider-img{
       
    }
    .testimonial-slider-img .slick-list .slick-slide.slick-center img{
        transform:scale(1.5)
    }
    .testimonial-slider-img .slick-list .slick-slide img{
        
    }
    .testimonial-slider-img .slick-list .slick-slide{
    }
    .projects{
        min-height:1090px;
    }
    
  }
  @media screen and (min-width:768px) and (max-width:992px){
    .contact-button, .menu-icon{
        display: block;
    }
    .main-menu ul, .header nav .get-in-touch-button{
        display:none;
    }
    .main-menu ul.slide{
        transform:translate(0%, 0%)
    }
    .main-menu > ul{
        position: fixed;
        left: 0;
        right: 0;
        padding-left:0;
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        margin:auto;
        transform-origin: 0% 0%;
        transform: translate(0px, -100%);
        transition: transform 1s linear;
        background: rgba(0,0,0,0.5);
    }
    .main-menu > ul li{
        width:100%;
        text-align:left;
        padding-left:10%;
        margin-right:0;
    }
    .main-menu > ul li.dropdown{
        position:relative;
    }
    .main-menu > ul li a{
        font-size:22px;
        line-height:40px;
    }
    .main-menu > ul li.dropdown a:before, .main-menu > ul li.dropdown a span{
        display:none;
    }
    .main-menu > ul.slide + .menu-icon{
        position:relative;
    }
    .menu-icon{
        float:right;
        color:#fff;
        font-size:20px;
    }
    .slider .home-slider h3{
        font-size:35px;
    }
    .home-s02{
        margin-top:50px;
    }
    .home-s02 .single-s02{
        padding-bottom: 0;
        margin-bottom:0;
    }
    .home-s02 .single-s02:nth-child(4){
        padding-bottom:0;
    }
    .home-s02 .single-s02:nth-child(5), .home-s02 .single-s02:last-child{
        padding-bottom:40px;
    }
    .home-s02 .single-s02 > div{
        height:max-content;
        top:0;
    }
    .home-s02 .single-s02 img{
        position: relative;
    }
    .partners{
        padding-bottom: 0;
    }
    .why-ana{
        padding-top:0;
    }
    .why-ana .container > .row{
        flex-direction: column-reverse;
    }
    .why-ana .why-ana-img img, .lets-work .lets-work-img img{
        width:max-content;
        float:none;
        display:block;
        margin:auto;
    }
    .why-ana .why-ana-text h2, .why-ana .why-ana-text p, .promotional-services .promotional-right{
        text-align:left;
    }
    .why-ana .why-ana-text a, .promotional-services .promotional-right img{
        float:left;
    }
    .counter .singleCounter > div{
        margin-bottom:35px;
        min-height:155px;
    }
    .promotional-services > .row{
        flex-direction:column-reverse;
    }
    .counter, .customers{
        padding-bottom:35px;
    }
    .testimonial-slider .slick-slide > div img{
        max-width:240px;
    }
    .subscribe > .full{
        margin-top:20px;
    }
    .footer-widget h4{
        margin-bottom:10px;
    }
    .footer-widget .about p{
        width:100%;
        margin-bottom:0;
    }
    .footer-widget > div{
        margin-bottom:25px;
    }
    .footer-section{
        background-position: bottom;
    background-repeat: no-repeat;
    }
    .reverse-column{
        flex-direction:row;
    }
    .testi-inner:before{
        display:none;
    }
    .service-sections .row{
        flex-direction:column-reverse;
    }
    .service-sections .service-img{
        position:relative;
    }
    .solution-sections .solutions-row:nth-child(odd){
        flex-direction:column-reverse;
    }
    .contact .contact-text h2{
        line-height:60px;
        font-size:50px;
    }
    .section-head.about-text{
        font-size:39px;
        line-height:40px;
    }
    .projects{
        min-height:930px;
    }
    .home-s02 .single-s02 div.icon{
        height:auto;
        top:0;
    }
    .lets-work{
        padding-top:150px;
    }
    .lets-work .lets-work-text h2{
        color:#fff;
    }
    .modal-lg .modal-body{
        padding-left:0;
        }
    .get-touch-popup{
        margin-left:0;
        margin-right:0;
        }
    .get-touch-popup .col-lg-6:first-child{
        padding-right:50px;
        }
    .get-intouch-img{
        background-image:linear-gradient(270deg,#2e8f8d,#233754);
        padding-top:15px;
        padding-bottom:15px;
        }
  }
  @media screen and (max-width:767px){
    .home-s02 .single-s02 div.icon{
        height:auto;
        top:0;
    }
    .container{
        max-width:95% !important;
    }
    .contact-button, .menu-icon{
        display: block;
    }
    .main-menu ul, .header nav .get-in-touch-button{
        display:none;
    }
    .main-menu ul.slide{
        transform:translate(0%, 0%)
    }
    .main-menu > ul{
        position: fixed;
        left: 0;
        right: 0;
        padding-left:0;
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        margin:auto;
        transform-origin: 0% 0%;
        transform: translate(0px, -100%);
        transition: transform 1s linear;
        background: rgba(0,0,0,0.8);
    }
    .main-menu > ul li{
        width:100%;
        text-align:left;
        padding-left:10%;
        margin-right:0;
    }
    .main-menu > ul li.dropdown{
        position:relative;
    }
    .main-menu > ul li a{
        font-size:22px;
        line-height:40px;
    }
    .main-menu > ul li.dropdown a:before, .main-menu > ul li.dropdown a span{
        display:none;
    }
    .main-menu > ul.slide + .menu-icon{
        position:relative;
    }
    .menu-icon{
        float:right;
        color:#fff;
        font-size:20px;
    }
    .home-s02 .single-s02{
        padding-bottom: 0;
        margin-bottom:0;
    }
    .home-s02 .single-s02:nth-child(4){
        padding-bottom:0;
    }
    .home-s02 .single-s02:nth-child(5), .home-s02 .single-s02:last-child{
        padding-bottom:40px;
    }
    .home-s02 .single-s02 > div{
        height:max-content;
        top:0;
    }
    .home-s02 .single-s02 img{
        position: relative;
    }
    .partners{
        padding-bottom: 40px;
    }
    .why-ana{
        padding-top:0;
    }
    .why-ana .container > .row{
        flex-direction: column-reverse;
    }
    .why-ana .why-ana-img img, .lets-work .lets-work-img img{
        width:max-content;
        float:none;
        display:block;
        margin:auto;
    }
    .why-ana .why-ana-img img{
        margin-top:40px;
    }
    .why-ana .why-ana-text h2, .why-ana .why-ana-text p, .promotional-services .promotional-right{
        text-align:left;
    }
    .why-ana .why-ana-text a, .promotional-services .promotional-right img{
        float:left;
    }
    
    .lets-work .lets-work-text h2, .promotional-services .promotional-right h2{
        margin-bottom:0;
    }
    .promotional-services .promotional-right img{
        margin-bottom:35px;
    }
    .counter .singleCounter > div{
        margin-bottom:35px;
        min-height:155px;
    }
    .promotional-services > .row{
        flex-direction:column-reverse;
    }
    .counter, .customers{
        padding-bottom:35px;
    }
    .testimonial-slider .slick-slide > div img{
        display:none;
    }
    .testimonial-slider .slick-slide > div .testi-content{
        max-width:98%;
        margin-left:1%;
    }
    .subscribe > .full{
        margin-top:20px;
    }
    .footer-widget h4{
        margin-bottom:10px;
    }
    .footer-widget .about p{
        width:100%;
        margin-bottom:0;
    }
    .footer-widget > div{
        margin-bottom:25px;
    }
    .counter .singleCounter p{
        min-height:52px;
    }
    .footer-section{
        background-position: bottom;
        background-repeat: no-repeat;
    }
    .section-head{
        margin-bottom:0;
    }
    .section-head h2{
        line-height:60px;
    }
    .home-s02{
        margin-top:50px;
    }
    .slider .home-slider h3{
        font-size:34px;
    }
    .reverse-column{
        flex-direction:column-reverse;
    }
    .testi-inner:before{
        display:none;
    }
    .service-sections .row{
        flex-direction:column-reverse;
    }
    .service-sections .service-img{
        position:relative;
        top:0;
    }
    .service-sections{
        padding:0 0 80px 0;
    }
    .solution-sections .solutions-row:nth-child(odd){
        flex-direction:column-reverse;
    }
    .about-s02 .about-img img{
        max-width:100%;
        width:auto;
    }
    .lets-work{
        padding-top:150px;
    }
    .lets-work .lets-work-text h2{
        color:#fff;
    }
    .main-menu ul li.dropdown:hover .dropdown-menu.services, 
    .main-menu ul li.dropdown:hover .dropdown-menu{
        height:0;
        transition:none;
    }
    .dd-respo{
        color:#fff;
    }
    .dd-respo .dropdown-menu{
        color: #fff;
    position: fixed;
    top: 0 !important;
    bottom: 0;
    width: 100% !important;
    overflow: hidden;
    left: 0 !important;
    right: 0 !important;
    border-radius: 0 !important;
    min-width: 100%;
    height: 100% !important;
    }
    .dd-respo .dropdown-menu.services .dropdown-single ul li{
        margin-bottom:0 !important;
    }
    .main-menu .dd-respo ul{
    display:block;
    }
    .submenu-close, .submenu-close2{
        display: block;
        position: fixed;
        z-index: 999999;
        right: 50px;
        top: 30px;
    }
    .dropdown-menu .dropdown-single:last-child{
        padding-left:15px
    }
    
    .submenu-close span, .submenu-close2 span{
        color:#000;
        font-size:30px;
    }
    .main-menu > ul li.dropdown.dd-respo a span{
        display:block;
    }
    .main-menu ul li.dropdown.dd-respo .dropdown-menu > .row{
        padding:30px 0;
    }
    .main-menu .dd-respo .dropdown-menu ul li{
        padding-left:5%;
    }
    .testimonials{
        padding-left:15px;
        padding-right:15px;
    }

    .modal-lg .modal-body{
        padding-left:0;
        }
    .get-touch-popup{
        margin-left:0;
        margin-right:0;
        }
    .get-touch-popup .col-lg-6:first-child{
        padding-right:50px;
        }
    .get-intouch-img{
        background-image:linear-gradient(270deg,#2e8f8d,#233754);
        padding-top:15px;
        padding-bottom:15px;
        }
  }
  @media screen and (max-width:667px){
    .why-ana .why-ana-img img, .lets-work .lets-work-img img{
        max-width:100%;
    }
    .section-head h2{
        font-size:50px;
    }
    .single-project-tab{
        width:100%;
    }
    .single-project-tab:nth-child(even){
        margin-left:0;
        margin-top:0;
    }
    .single-project-tab:nth-child(3){
        margin-top:0;
    }
    .home-s02 .single-s02 h2{
        font-size:24px;
    }
    .slider .home-slider h3{
        font-size:30px;
    }
    .single-jobs{
        flex-direction:column;
        align-items:flex-start;
        text-align:left;
    }
    .project-tabs ul{
        overflow-x: scroll;
        width: auto;
        display: flex;
        padding-bottom:2px;
    }
    
  }
  @media screen and (max-width:640px){
    .slider .home-slider h3{
        line-height:45px;
    }
    .slider .home-slider h3 br{
        display:none;
    }
    .customers{
        padding-bottom:70px;
    }
  }
  @media screen and (max-width:568px){
    .section-head h2{
        font-size:45px;
    }
    .promotional-services .promotional-right h2{
        margin-bottom:15px;
    }
    .subscribe > .half{
        margin-bottom:20px;
    }
    .subscribe > .full{
        margin-top:0;
    }
    .testimonial-slider .slick-slide > div .inner-testimonials{
        max-width:100%;
        padding:0;
    }
    .home-s02 .single-s02{
        width:49%;
        padding-top:0;
    }
    .counter .singleCounter p{
        min-height:26px;
    }
    .lets-work{
        padding-top:100px;
    }
    .slick-arrow {
        right:-10px !important;
    }
    .slick-arrow:before{
        color:#000 !important;
        font-size: 25px !important;
    } 
    .slick-prev{
        right: auto;
        left: -13px !important;
    }
  }
  @media screen and (max-width:512px){
    .promotional-services .promotional-right h2{
        line-height:40px;
    }
    .promotional-left > .animated{
        width:100%;
    }
    .promotional-left .single-promo{
        flex-direction:column;
        align-items:center;
    }
    .single-promo .single-promo-text{
        text-align:center;
    }
    .partners{
        padding-bottom:10px;
    }
    .project-tabs ul li{
        font-size:15px;
    }
    #subscription-form .subs-email{
        width:70%;
    }
    #subscription-form .submit-btn{
        width:30%;
    }
    .section-head h2{
        font-size:42px;
    }
  }
@media screen and (max-width:480px){
    .section-head h2{
        font-size:40px;
    }
    .promotional-services .promotional-right img{
        width:100%;
    }
    .project-tabs ul li{
        float: none;
        display: block;
        text-align: center;
    }
    form#get-in-touch-form .half{
        width:100%;
    }
    form#get-in-touch-form .half:nth-child(even){
        margin-left:0;
    }
    .testimonial-slider .slick-slide > div .testi-content{
        padding:20px;
    }
    .section-head p{
        font-size:14px;
    }
    .service-text ul li{
        font-size:14px;
    }
    .section-head.solutions-text.security-service h2{
        line-height:50px;
    }
    .home-s02 .single-s02{
        width:98%;
    }
    .home-s02 .single-s02 img{
        max-width:60%;
        float:none;
        margin:auto;
    }
    .home-s02 .single-s02 h2{
        margin-top:-30px;
    }
    .lets-work .lets-work-text{
        margin-bottom:35px;
    }
    .counter{
        padding-left:15px;
        padding-right:15px;
    }
}
@media screen and (max-width:414px){
    .slider .home-slider h3{
        font-size:25px;
        line-height:40px;
    }
    .slider{
        padding-top:140px;
    }
    .lets-work .lets-work-text h2{
        font-size:37px;
        line-height:50px;
    }
    .counter .singleCounter > div{
        min-height:max-content;
    }
    .counter .singleCounter p{
        min-height:max-content;
    }
    .promotional-services .promotional-right h2{
        font-size:34px;
    }
    .customer-slider .slick-slide > div img{
        float:none;
        margin:auto;
    }
    .subheader ul{
        display:none;
    }
    .section-head.service-text h2, .section-head.solutions-text h2{
        font-size:40px;
        line-height:45px;
    }
    .footer-section > .row > .container{
        padding-left:0;
        padding-right:0;
    }
    .service-text ul li, .service-text p{
        text-align:left;
    }
    .section-head.solutions-text.security-service h2{
        line-height:45px;
    }
    .single-jobs h4:last-child{
        line-height:30px;
    }
    .single-project-tab>img{
        min-height: 250px;
        object-fit: cover;
        border-radius: 20px;
    }

}
@media screen and (max-width:375px){
    
    .lets-work .lets-work-text h2, .promotional-services .promotional-right h2{
        font-size:31px;
    }
    .section-head h2, .section-head.service-text h2, .section-head.solutions-text h2{
        font-size:35px;
    }
    form#get-in-touch-form{
        padding:35px 15px;
    }
    .single-project-tab .single-project-content{
        padding:20px 30px;
        }
        .get-touch-popup .col-lg-6:first-child{
        padding-left:20px;
        padding-right:20px;
        padding-top:20px;
        padding-bottom:20px;
        }
}
@media screen and (max-width:320px){
    .slider .home-slider h3{
        font-size:21px;
        line-height:34px;
    }
    .lets-work .lets-work-text h2, .promotional-services .promotional-right h2{
        font-size:26px;
    }
    .promotional-left .single-promo{
        padding:15px;
    }
    .single-project-tab .single-project-content{
        padding:0 15px;
    }
    .single-project-content h2{
        font-size:22px;
        line-height:26px;
    }
    .single-project-content p{
        font-size:14px;
        line-height:26px;
    }
    .section-head h2{
        font-size:32px;
    }
    .section-head.service-text h2, .section-head.solutions-text h2{
        line-height:36px;
        font-size:30px;
    }
    .testimonials.testi-inner .testimonial-section{
        padding-left:3px;
    }
}
.preloader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin:auto;
    width: 100%;
    height: 100vh;
    z-index: 99999999;
    /* background-image:linear-gradient(to right,#154a58,#1d677b, #164d5b); */
    background-color:#fff;
    background-position: center;
    display:flex;
    align-items:center;
    justify-content: center;
}
.preloader img{
    width:300px;
    
}
.addLogo2{
    display: block !important;
}

.loader--hide {
    opacity: 0;
    z-index: -1;
  }
  .fixBody{
      position:fixed;
      left:0;
      right:0;
      top:0;
      bottom:0;
  }
@keyframes preLoaderImage{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.3);
    }
    100%{
        transform: scale(1);
    }
}
.view-service-button{
    padding-top:50px;
}
.view-service-button .view-services{
    text-align:center;
}
.view-service-button .view-services a{
    float:none;
    display:inline-block;
    margin:auto;
}
.view-service-button .view-services a:hover{
    color:#fff;
}